import React from 'react';

function About() {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8">About OffMarket Tacoma</h1>
      <div className="prose max-w-none">
        <p className="text-lg mb-6">
          We are local real estate investors in Tacoma who buy houses directly from homeowners.
          Our mission is to provide a fast, hassle-free selling experience.
        </p>
      </div>
    </div>
  );
}

export default About; 