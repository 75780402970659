import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="bg-white text-[#228B22] p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <Link 
          to="/" 
          className="text-2xl font-bold font-['Orbitron'] tracking-[0.2em] bg-gradient-to-l from-green-600 via-green-500 to-green-400 bg-clip-text text-transparent drop-shadow-[0_0_8px_rgba(34,255,34,0.5)]"
          style={{
            textShadow: `
              0 0 20px rgba(34,255,34,0.5),
              0 0 40px rgba(34,255,34,0.3),
              inset 0 0 10px rgba(255,255,255,0.8),
              0 0 5px rgba(255,255,255,0.5)
            `,
            filter: 'drop-shadow(0 0 4px rgba(34,255,34,0.6))'
          }}
        >
          OFF MARKET TACOMA
        </Link>
        <div className="space-x-6">
          <Link to="/" className="hover:text-green-700">Home</Link>
          <Link to="/how-it-works" className="hover:text-green-700">How It Works</Link>
          <Link to="/about" className="hover:text-green-700">About</Link>
          <Link to="/contact" className="hover:text-green-700">Contact</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 