import React from 'react';
import { Link } from 'react-router-dom';
import tacomaImage from '../images/tacoma.jpg';

function Home() {
  return (
    <div className="min-h-screen">
      <div className="relative">
        {/* Background image with gradient overlay */}
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${tacomaImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <div 
          className="absolute inset-0 z-0 bg-gradient-to-l from-lime-500/90 via-green-600/80 to-transparent"
        />
        
        {/* Content */}
        <div className="relative z-10 bg-transparent text-white py-20">
          <div className="container mx-auto text-center">
            <h1 className="text-5xl font-bold mb-6">Sell Your Tacoma House Fast</h1>
            <p className="text-xl mb-8">Get a fair cash offer within 24 hours</p>
            <Link 
              to="/contact" 
              className="bg-green-300 text-green-900 px-8 py-4 rounded-lg text-xl font-bold hover:bg-green-200"
            >
              Get Your Cash Offer
            </Link>
          </div>
        </div>
      </div>
      
      <div className="container mx-auto py-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center p-6">
            <h3 className="text-2xl font-bold mb-4">Fast Closing</h3>
            <p>Close in as little as 7 days or on your timeline</p>
          </div>
          <div className="text-center p-6">
            <h3 className="text-2xl font-bold mb-4">No Repairs Needed</h3>
            <p>Sell your house as-is, we handle all repairs</p>
          </div>
          <div className="text-center p-6">
            <h3 className="text-2xl font-bold mb-4">No Fees</h3>
            <p>No real estate commissions or closing costs</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home; 