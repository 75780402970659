import React from 'react';

function HowItWorks() {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8">How It Works</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">1. Contact Us</h3>
          <p>Fill out our simple form or give us a call to get started.</p>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">2. Get Your Offer</h3>
          <p>We'll present you with a fair, no-obligation cash offer within 24 hours.</p>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h3 className="text-2xl font-bold mb-4">3. Close Quick</h3>
          <p>Close on your timeline, as quick as 7 days or whenever you're ready.</p>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks; 